<template>
  <div class="app-content-box bg-gray">
    <div class="container wrapper-empty">
      <div class="book-heading-big text-center mt-4">Dodaj książkę</div>
      <div class="empty-shelf-icon text-center mt-4">
        <img src="../assets/app_icon/pusta-polka.png" alt="pusta-polka"/>
      </div>
      <div class="book-heading-medium text-center mt-5">Wyszukaj lub zeskanuj książki</div>
      <router-link to="/wyszukiwanie"><button class="btn-black-full w-100 mt-5">Wyszukaj książkę</button></router-link>
      <div v-if="platform !== 'ios'"><router-link to="/skanuj"><button class="btn-black-full w-100 mt-2"><a href="#" class="text-white">Zeskanuj książkę</a></button></router-link></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EmptyShelf',
  data () {
    return {
      platform: window.platform
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
  }
  .empty-shelf-icon img {
    width: 150px;
    height: 150px;
  }
</style>
